import React, { useState } from "react";
import FileUploader from "react-firebase-file-uploader";
import Swal from "sweetalert2";
import ProgressBar from "@ramonak/react-progress-bar";
import { db, storage } from "../../../firebase";
import Switch from "react-switch";

const Reports = ({ userData }) => {
  const [alumno, setAlumno] = useState("");
  const [semana, setSemana] = useState(0);
  const [uploadReport, setUploadReport] = useState(false);
  const [nameFileReport, setNameFileReport] = useState("");
  const [proFileReport, setProFileReport] = useState(0);
  const [messageUploadReport, setMessageUploadReport] = useState(false);
  const [urlFileReport, setUrlFileReport] = useState("");
  const [publicarAlumno, setPublicarAlumno] = useState(false);

  const handleChecked = () => {
    setPublicarAlumno(!publicarAlumno);
    console.log(!publicarAlumno);
  };

  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: "btn btn-success",
      cancelButton: "btn btn-danger",
    },
    buttonsStyling: false,
  });

  const handleUploadStartReport = () => {
    setProFileReport(0);
    setUploadReport(true);
  };
  const handleProgressReport = (progress) =>
    setProFileReport({
      progress,
    });
  const handleUploadErrorReport = (error) => {
    setUploadReport(error);
    console.log(error);
  };
  const handleUploadSuccessReport = (name) => {
    setProFileReport(100);
    setUploadReport(false);
    setNameFileReport(name);
    storage
      .ref(`${userData.emailProfesor}/reportes_24-25`)
      .child(name)
      .getDownloadURL()
      .then((url) => {
        setUrlFileReport(url);
      });
    setMessageUploadReport(true);
  };

  const addReport = (event) => {
    event.preventDefault();
    if (!alumno.trim()) {
      Swal.fire({
        title: "¡Error!",
        text: "Debes seleccionar un alumno",
        icon: "warning",
        confirmButtonText: "Ok",
      });
      return;
    }
    if (!semana.trim()) {
      Swal.fire({
        title: "¡Error!",
        text: "Debes agregar una semana",
        icon: "warning",
        confirmButtonText: "Ok",
      });
    }
    swalWithBootstrapButtons
      .fire({
        title: "¿Estás seguro que deseas agregar el reporte? 👀",
        text: `Alumno: ${alumno} Semana: ${semana}`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Sí, agregar",
        cancelButtonText: "No, cancelar",
        reverseButtons: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          const reportAlunmo = {
            Reporte: {
              date: Date.now(),
              alumno,
              semana,
              urlFileReport,
              publicarAlumno,
            },
          };
          try {
            db.collection(`reportes_ingles_24-25`)
              .doc(`Semana${semana}_${alumno}`)
              .set(reportAlunmo);
            swalWithBootstrapButtons.fire(
              "Agregada",
              "El reporte se ha agregado correctamente. 🤠",
              "success"
            );
            setAlumno("");
            setSemana(0);
            setUrlFileReport("");
            setPublicarAlumno(false);
            setMessageUploadReport(false);
            setProFileReport(0);
            setNameFileReport("");
            setUploadReport(false);
          } catch (error) {
            console.log(error);
          }
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          swalWithBootstrapButtons.fire(
            "Cancelado",
            "El reporte no se ha agregado. 😢",
            "error"
          );
        }
      });
  };
  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <h2 className="mb-4 text-center margin-top">Reportes semanales</h2>
          <div className="tasks-professor__title my-5">
            <h4>Puedes agregar los reportes semanales de los alumnos.</h4>
          </div>
          <form onSubmit={addReport}>
            <div className="form-group formTasks">
              <label>Alumno</label>
              <select
                className="form-control mb-4"
                placeholder="Alumno"
                name="nombreAlumno"
                value={alumno}
                onChange={(event) => setAlumno(event.target.value)}
              >
                {userData.alumnos.map((alumno, index) => {
                  return (
                    <option key={index} value={alumno}>
                      {alumno}
                    </option>
                  );
                })}
              </select>
              <label>
                Semena
                <input
                  type="number"
                  name="semanaReporte"
                  placeholder="Ingresa la semana del reporte"
                  className="form-control mb-4"
                  value={semana}
                  onChange={(event) => setSemana(event.target.value)}
                />
              </label>
              <label className="input-file-upload">
                Selecciona un archivo
                <FileUploader
                  className="input-custom"
                  accept=".PDF"
                  name="archivoTask"
                  randomizeFilename
                  storageRef={storage.ref(
                    `${userData.emailProfesor}/reportes_22-23`
                  )}
                  onUploadStart={handleUploadStartReport}
                  onUploadError={handleUploadErrorReport}
                  onUploadSuccess={handleUploadSuccessReport}
                  onProgress={handleProgressReport}
                />
                {proFileReport.progress > 1 ? (
                  <ProgressBar
                    completed={proFileReport.progress}
                    bgColor="rgb(255, 24, 24) "
                  />
                ) : null}
                {messageUploadReport && (
                  <p className="message-upload">
                    El archivo se ha subido correctamente
                  </p>
                )}
              </label>
              {userData.idRolePermisos && (
                <div className="container-checked">
                  <p>Publicar:</p>
                  <Switch
                    onChange={handleChecked}
                    onColor={"#1a237e"}
                    checked={publicarAlumno}
                  />
                </div>
              )}
            </div>
            <div className="btn-addTask">
              <button
                className="btn-addTask"
                type="submit"
                value="Agregar "
                onSubmit={addReport}
              >
                Agregar
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Reports;
