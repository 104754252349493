import React, { useState, Fragment } from "react";
import "./EditTask.css";
import FileUploader from "react-firebase-file-uploader";
import ProgressBar from "@ramonak/react-progress-bar";
import Swal from "sweetalert2";
import Switch from "react-switch";
import { db, storage } from "../../../firebase";

const EditTask = (props) => {
  const { userData } = props;
  //useState
  const [modeEditTask, setModeEditTask] = useState(false);
  const [materiaSearch, setMateriaSearch] = useState("");
  const [semanaSearch, setSemanaSearch] = useState("0");
  const [taskSelected, setTaskSelected] = useState({});
  const [gradoSearch, setGradoSearch] = useState("");

  //states tarea a editar
  const [materiaTask, setMateriaTask] = useState("");
  const [gradoTask, setGradoTask] = useState("");
  const [semanaTask, setSemanaTask] = useState(0);

  //states de la tarea a editar
  const [materiaEditTask, setMateriaEditTask] = useState("");
  const [entregaEditTask, setEntregaEditTask] = useState("");
  const [gradoEditTask, setGradoEditTask] = useState("");
  const [semanaEditTask, setSemanaEditTask] = useState(0);
  const [contenidoEditTask, setContenidoEditTask] = useState("");
  const [linkEditTask1, setEditLinkTask1] = useState("");
  const [linkEditTask2, setEditLinkTask2] = useState("");
  const [linkEditTask3, setEditLinkTask3] = useState("");
  const [linkEditTask4, setEditLinkTask4] = useState("");
  const [linkEditTask5, setEditLinkTask5] = useState("");
  const [linkEditTask6, setEditLinkTask6] = useState("");
  const [linkEditTask7, setEditLinkTask7] = useState("");
  const [linkEditTask8, setEditLinkTask8] = useState("");
  const [linkEditTask9, setEditLinkTask9] = useState("");
  const [linkEditTask10, setEditLinkTask10] = useState("");
  const [linkEditTask11, setEditLinkTask11] = useState("");
  const [linkEditTask12, setEditLinkTask12] = useState("");
  const [linkEditSesionZoom, setEditLinkSesionZoom] = useState("");
  const [editSesionDate, setEditSesionDate] = useState("");
  const [linkEditSesionZoom2, setEditLinkSesionZoom2] = useState("");
  const [editSesionDate2, setEditSesionDate2] = useState("");
  const [publicarAlumno, setPublicarAlumno] = useState(false);

  //states pdfs
  //PDF
  const [nameFilePDF, setNameFilePDF] = useState("");
  const [uploadFilePDF, setUploadFilePDF] = useState(false);
  const [proFilePDF, setProFilePDF] = useState(0);
  const [urlFilePDF, setUrlFilePDF] = useState("");
  const [messageUploadPDF, setMessageUploadPDF] = useState(false);
  //PDF2
  const [nameFilePDF2, setNameFilePDF2] = useState("");
  const [uploadFilePDF2, setUploadFilePDF2] = useState(false);
  const [proFilePDF2, setProFilePDF2] = useState(0);
  const [urlFilePDF2, setUrlFilePDF2] = useState("");
  const [messageUploadPDF2, setMessageUploadPDF2] = useState(false);
  //PDF3
  const [nameFilePDF3, setNameFilePDF3] = useState("");
  const [uploadFilePDF3, setUploadFilePDF3] = useState(false);
  const [proFilePDF3, setProFilePDF3] = useState(0);
  const [urlFilePDF3, setUrlFilePDF3] = useState("");
  const [messageUploadPDF3, setMessageUploadPDF3] = useState(false);
  //PDF4
  const [nameFilePDF4, setNameFilePDF4] = useState("");
  const [uploadFilePDF4, setUploadFilePDF4] = useState(false);
  const [proFilePDF4, setProFilePDF4] = useState(0);
  const [urlFilePDF4, setUrlFilePDF4] = useState("");
  const [messageUploadPDF4, setMessageUploadPDF4] = useState(false);
  //PDF5
  const [nameFilePDF5, setNameFilePDF5] = useState("");
  const [uploadFilePDF5, setUploadFilePDF5] = useState(false);
  const [proFilePDF5, setProFilePDF5] = useState(0);
  const [urlFilePDF5, setUrlFilePDF5] = useState("");
  const [messageUploadPDF5, setMessageUploadPDF5] = useState(false);
  //PDF6
  const [nameFilePDF6, setNameFilePDF6] = useState("");
  const [uploadFilePDF6, setUploadFilePDF6] = useState(false);
  const [proFilePDF6, setProFilePDF6] = useState(0);
  const [urlFilePDF6, setUrlFilePDF6] = useState("");
  const [messageUploadPDF6, setMessageUploadPDF6] = useState(false);

  //función para cambiar checked
  const handleChecked = () => {
    setPublicarAlumno(!publicarAlumno);
  };

  //funciones para subir archivos
  //PDF
  const handleUploadStartPDF = (name) => {
    setNameFilePDF(name.name);
    setProFilePDF(0);
    setUploadFilePDF(true);
  };
  const handleProgressPDF = (progress) =>
    setProFilePDF({
      progress,
    });

  const handleUploadErrorPDF = (error) => {
    setUploadFilePDF(error);
    console.log(error);
  };
  const handleUploadSuccessPDF = (name) => {
    setProFilePDF(100);
    setUploadFilePDF(false);
    storage
      .ref(`${userData.emailProfesor}/tareas`)
      .child(name)
      .getDownloadURL()
      .then((url) => {
        setUrlFilePDF(url);
      });
    setMessageUploadPDF(true);
  };
  //PDF02
  const handleUploadStartPDF2 = (name) => {
    setNameFilePDF2(name.name);
    setProFilePDF2(0);
    setUploadFilePDF2(true);
  };
  const handleProgressPDF2 = (progress) =>
    setProFilePDF2({
      progress,
    });

  const handleUploadErrorPDF2 = (error) => {
    setUploadFilePDF2(error);
    console.log(error);
  };
  const handleUploadSuccessPDF2 = (name) => {
    setProFilePDF2(100);
    setUploadFilePDF2(false);
    storage
      .ref(`${userData.emailProfesor}/tareas`)
      .child(name)
      .getDownloadURL()
      .then((url) => {
        setUrlFilePDF2(url);
      });
    setMessageUploadPDF2(true);
  };
  //PDF03
  const handleUploadStartPDF3 = (name) => {
    setNameFilePDF3(name.name);
    setProFilePDF3(0);
    setUploadFilePDF3(true);
  };
  const handleProgressPDF3 = (progress) =>
    setProFilePDF3({
      progress,
    });

  const handleUploadErrorPDF3 = (error) => {
    setUploadFilePDF3(error);
    console.log(error);
  };
  const handleUploadSuccessPDF3 = (name) => {
    setProFilePDF3(100);
    setUploadFilePDF3(false);
    storage
      .ref(`${userData.emailProfesor}/tareas`)
      .child(name)
      .getDownloadURL()
      .then((url) => {
        setUrlFilePDF3(url);
      });
    setMessageUploadPDF3(true);
  };
  //PDF04
  const handleUploadStartPDF4 = (name) => {
    setNameFilePDF4(name.name);
    setProFilePDF4(0);
    setUploadFilePDF4(true);
  };
  const handleProgressPDF4 = (progress) =>
    setProFilePDF4({
      progress,
    });

  const handleUploadErrorPDF4 = (error) => {
    setUploadFilePDF4(error);
    console.log(error);
  };
  const handleUploadSuccessPDF4 = (name) => {
    setProFilePDF4(100);
    setUploadFilePDF4(false);
    storage
      .ref(`${userData.emailProfesor}/tareas`)
      .child(name)
      .getDownloadURL()
      .then((url) => {
        setUrlFilePDF4(url);
      });
    setMessageUploadPDF4(true);
  };
  //PDF 5
  const handleUploadStartPDF5 = (name) => {
    setNameFilePDF5(name.name);
    setProFilePDF5(0);
    setUploadFilePDF5(true);
  };
  const handleProgressPDF5 = (progress) =>
    setProFilePDF5({
      progress,
    });

  const handleUploadErrorPDF5 = (error) => {
    setUploadFilePDF5(error);
    console.log(error);
  };
  const handleUploadSuccessPDF5 = (name) => {
    setProFilePDF5(100);
    setUploadFilePDF5(false);
    storage
      .ref(`${userData.emailProfesor}/tareas`)
      .child(name)
      .getDownloadURL()
      .then((url) => {
        setUrlFilePDF5(url);
      });
    setMessageUploadPDF5(true);
  };

  //PDF 6
  const handleUploadStartPDF6 = (name) => {
    setNameFilePDF6(name.name);
    setProFilePDF6(0);
    setUploadFilePDF6(true);
  };
  const handleProgressPDF6 = (progress) =>
    setProFilePDF6({
      progress,
    });

  const handleUploadErrorPDF6 = (error) => {
    setUploadFilePDF6(error);
    console.log(error);
  };
  const handleUploadSuccessPDF6 = (name) => {
    setProFilePDF6(100);
    setUploadFilePDF6(false);
    storage
      .ref(`${userData.emailProfesor}/tareas`)
      .child(name)
      .getDownloadURL()
      .then((url) => {
        setUrlFilePDF6(url);
      });
    setMessageUploadPDF6(true);
  };

  //funciónpara buscar una tarea por materia y por semana
  const handleMateriaSearch = async (event) => {
    event.preventDefault();
    if (!gradoSearch) {
      Swal.fire({
        title: "¡Error!",
        text: "Debes agregar un grado",
        icon: "warning",
        confirmButtonText: "Ok",
      });
      return;
    }
    if (gradoSearch === "selecciona una opción por favor") {
      Swal.fire({
        title: "¡Error!",
        text: "El grado no es válido",
        icon: "warning",
        confirmButtonText: "Ok",
      });
      return;
    }
    if (!materiaSearch.trim()) {
      Swal.fire({
        title: "¡Error!",
        text: "Debes agregar el nombre de la materia",
        icon: "warning",
        confirmButtonText: "Ok",
      });
      return;
    }
    if (!semanaSearch) {
      Swal.fire({
        title: "¡Error!",
        text: "Debes agregar una semana",
        icon: "warning",
        confirmButtonText: "Ok",
      });
      return;
    }
    if (materiaSearch === "selecciona una opción por favor") {
      Swal.fire({
        title: "¡Error!",
        text: "La materia no es válida",
        icon: "warning",
        confirmButtonText: "Ok",
      });
      return;
    }

    try {
      const taskStudentContain = db
        .collection(`${materiaSearch}_22-23`)
        .doc(
          `Semana${semanaSearch}_${gradoSearch}_${materiaSearch.toLowerCase()}`
        );
      await taskStudentContain.get().then(function (doc) {
        if (doc.exists && doc.data().Tarea.publicar) {
          setTaskSelected(doc.data().Tarea);
          //tarea a editar
          setPublicarAlumno(doc.data().Tarea.publicarAlumno);
          setSemanaTask(doc.data().Tarea.semana);
          setGradoTask(doc.data().Tarea.grado);
          setMateriaTask(doc.data().Tarea.materia);
          setModeEditTask(true);
          setMateriaEditTask(doc.data().Tarea.materia);
          setGradoEditTask(doc.data().Tarea.grado);
          setSemanaEditTask(doc.data().Tarea.semana);
          setEntregaEditTask(doc.data().Tarea.fechaEntrega);
          setContenidoEditTask(doc.data().Tarea.contenido);
          setEditLinkTask1(doc.data().Tarea.linkVideo1);
          setEditLinkTask2(doc.data().Tarea.linkVideo2);
          setEditLinkTask3(doc.data().Tarea.linkVideo3);
          setEditLinkTask4(doc.data().Tarea.linkVideo4);
          setEditLinkTask5(doc.data().Tarea.linkVideo5);
          setEditLinkTask6(doc.data().Tarea.linkVideo6);
          setEditLinkTask7(doc.data().Tarea.linkArchivo1);
          setEditLinkTask8(doc.data().Tarea.linkArchivo2);
          setEditLinkTask9(doc.data().Tarea.linkArchivo3);
          setEditLinkTask10(doc.data().Tarea.linkArchivo4);
          setEditLinkTask11(doc.data().Tarea.linkArchivo5);
          setEditLinkTask12(doc.data().Tarea.linkArchivo6);
          setEditLinkSesionZoom(doc.data().Tarea.linkSesionZoom);
          setEditSesionDate(doc.data().Tarea.sesionDate);
          setEditLinkSesionZoom2(doc.data().Tarea.linkSesionZoom2);
          setEditSesionDate2(doc.data().Tarea.sesionDate2);
          setUrlFilePDF(doc.data().Tarea.urlFilePDF1);
          setUrlFilePDF2(doc.data().Tarea.urlFilePDF2);
          setUrlFilePDF3(doc.data().Tarea.urlFilePDF3);
          setUrlFilePDF4(doc.data().Tarea.urlFilePDF4);
          setUrlFilePDF5(doc.data().Tarea.urlFilePDF5);
          setUrlFilePDF6(doc.data().Tarea.urlFilePDF6);
        } else {
          Swal.fire({
            title: "¡Error!",
            text: "La tarea no existe o no está disponible",
            icon: "error",
            confirmButtonText: "Ok",
          });
        }
        return;
      });
    } catch (error) {
      console.log(error);
      console.log("errrooooor");
    }
  };
  //funciónpara buscar una tarea por materia y por semanana Director
  const handleMateriaSearchDirector = async (event) => {
    event.preventDefault();
    if (!gradoSearch) {
      Swal.fire({
        title: "¡Error!",
        text: "Debes agregar un grado",
        icon: "warning",
        confirmButtonText: "Ok",
      });
      return;
    }
    if (gradoSearch === "selecciona una opción por favor") {
      Swal.fire({
        title: "¡Error!",
        text: "El profesor no es válido",
        icon: "warning",
        confirmButtonText: "Ok",
      });
      return;
    }
    if (gradoSearch === "selecciona una opción por favor") {
      Swal.fire({
        title: "¡Error!",
        text: "El grado no es válido",
        icon: "warning",
        confirmButtonText: "Ok",
      });
      return;
    }
    if (!materiaSearch.trim()) {
      Swal.fire({
        title: "¡Error!",
        text: "Debes agregar el nombre de la materia",
        icon: "warning",
        confirmButtonText: "Ok",
      });
      return;
    }
    if (!semanaSearch) {
      Swal.fire({
        title: "¡Error!",
        text: "Debes agregar una semana",
        icon: "warning",
        confirmButtonText: "Ok",
      });
      return;
    }
    if (materiaSearch === "selecciona una opción por favor") {
      Swal.fire({
        title: "¡Error!",
        text: "La materia no es válida",
        icon: "warning",
        confirmButtonText: "Ok",
      });
      return;
    }

    try {
      const taskStudentContain = db
        .collection(`${materiaSearch}_22-23`)
        .doc(
          `Semana${semanaSearch}_${gradoSearch}_${materiaSearch.toLowerCase()}`
        );
      await taskStudentContain.get().then(function (doc) {
        if (doc.exists && doc.data().Tarea.publicar) {
          setTaskSelected(doc.data().Tarea);
          //tarea a editar
          setPublicarAlumno(doc.data().Tarea.publicarAlumno);
          setSemanaTask(doc.data().Tarea.semana);
          setGradoTask(doc.data().Tarea.grado);
          setMateriaTask(doc.data().Tarea.materia);
          setModeEditTask(true);
          setMateriaEditTask(doc.data().Tarea.materia);
          setGradoEditTask(doc.data().Tarea.grado);
          setSemanaEditTask(doc.data().Tarea.semana);
          setEntregaEditTask(doc.data().Tarea.fechaEntrega);
          setContenidoEditTask(doc.data().Tarea.contenido);
          setEditLinkTask1(doc.data().Tarea.linkVideo1);
          setEditLinkTask2(doc.data().Tarea.linkVideo2);
          setEditLinkTask3(doc.data().Tarea.linkVideo3);
          setEditLinkTask4(doc.data().Tarea.linkVideo4);
          setEditLinkTask5(doc.data().Tarea.linkVideo5);
          setEditLinkTask6(doc.data().Tarea.linkVideo6);
          setEditLinkTask7(doc.data().Tarea.linkArchivo1);
          setEditLinkTask8(doc.data().Tarea.linkArchivo2);
          setEditLinkTask9(doc.data().Tarea.linkArchivo3);
          setEditLinkTask10(doc.data().Tarea.linkArchivo4);
          setEditLinkTask11(doc.data().Tarea.linkArchivo5);
          setEditLinkTask12(doc.data().Tarea.linkArchivo6);
          setEditLinkSesionZoom(doc.data().Tarea.linkSesionZoom);
          setEditSesionDate(doc.data().Tarea.sesionDate);
          setEditLinkSesionZoom2(doc.data().Tarea.linkSesionZoom2);
          setEditSesionDate2(doc.data().Tarea.sesionDate2);
          setUrlFilePDF(doc.data().Tarea.urlFilePDF1);
          setUrlFilePDF2(doc.data().Tarea.urlFilePDF2);
          setUrlFilePDF3(doc.data().Tarea.urlFilePDF3);
          setUrlFilePDF4(doc.data().Tarea.urlFilePDF4);
          setUrlFilePDF5(doc.data().Tarea.urlFilePDF5);
          setUrlFilePDF6(doc.data().Tarea.urlFilePDF6);
        } else {
          Swal.fire({
            title: "¡Error!",
            text: "La tarea no existe o no está disponible",
            icon: "error",
            confirmButtonText: "Ok",
          });
        }
        return;
      });
    } catch (error) {
      console.log(error);
    }
  };

  const editTaskProfessor = (event) => {
    event.preventDefault();
    if (!entregaEditTask.trim()) {
      Swal.fire({
        title: "¡Error!",
        text: "Debes agregar una fecha de entrega",
        icon: "warning",
        confirmButtonText: "Ok",
      });
      return;
    }
    const editTarea = {
      Tarea: {
        publicar: true,
        publicarAlumno: publicarAlumno,
        grado: gradoEditTask,
        materia: materiaEditTask,
        fechaEntrega: entregaEditTask,
        semana: semanaEditTask,
        contenido: contenidoEditTask,
        linkVideo1: linkEditTask1,
        linkVideo2: linkEditTask2,
        linkVideo3: linkEditTask3,
        linkVideo4: linkEditTask4,
        linkVideo5: linkEditTask5,
        linkVideo6: linkEditTask6,
        linkArchivo1: linkEditTask7,
        linkArchivo2: linkEditTask8,
        linkArchivo3: linkEditTask9,
        linkArchivo4: linkEditTask10,
        linkArchivo5: linkEditTask11,
        linkArchivo6: linkEditTask12,
        linkSesionZoom: linkEditSesionZoom,
        sesionDate: editSesionDate,
        // linkSesionZoom2: linkEditSesionZoom2,
        // sesionDate2: editSesionDate2,
        urlFilePDF1: urlFilePDF,
        urlFilePDF2: urlFilePDF2,
        urlFilePDF3: urlFilePDF3,
        urlFilePDF4: urlFilePDF4,
        urlFilePDF5: urlFilePDF5,
        urlFilePDF6: urlFilePDF6,
      },
    };
    if(linkEditSesionZoom2 !== undefined){
      editTarea.Tarea = {
        ...editTarea.Tarea,
        linkSesionZoom2: linkEditSesionZoom2,
      };
    }
    if(editSesionDate2 !== undefined){
      editTarea.Tarea = {
        ...editTarea.Tarea,
        sesionDate2: editSesionDate2,
      };
    }

    try {
      const documentRef = db
      .collection(`${materiaSearch}_22-23`)
      .doc(`Semana${semanaSearch}_${gradoSearch}_${materiaSearch.toLowerCase()}`);
    
      documentRef
        .set(editTarea, { merge: true })
        .then(() => {
          console.log("Documento actualizado exitosamente con nuevos campos");
        })
        .catch((error) => {
          console.error("Error al actualizar el documento: ", error);
        });

      //Reset form
      setModeEditTask(false);
      setSemanaTask(0);
      setMateriaSearch("");
      setSemanaSearch("");
      setGradoSearch("");
      setGradoTask("");
      setMateriaTask("");
      setMateriaEditTask("");
      setGradoEditTask("");
      setSemanaEditTask(0);
      setEntregaEditTask("");
      setContenidoEditTask("");
      setEditLinkTask1("");
      setEditLinkTask2("");
      setEditLinkTask3("");
      setEditLinkTask4("");
      setEditLinkTask5("");
      setEditLinkTask6("");
      setEditLinkTask7("");
      setEditLinkTask8("");
      setEditLinkTask9("");
      setEditLinkTask10("");
      setEditLinkSesionZoom("");
      setEditSesionDate("");
      setEditLinkSesionZoom2("");
      setEditSesionDate2("");
      setUrlFilePDF("");
      setUrlFilePDF2("");
      setUrlFilePDF3("");
      setUrlFilePDF4("");
      setUrlFilePDF5("");
      setUrlFilePDF6("");
      Swal.fire({
        title: "¡Excelente!",
        text: "La tarea se ha sido editada correctamente",
        icon: "success",
        confirmButtonText: "Ok",
      });
    } catch (error) {
      console.log(error);
    }
  };
  //función para editar una tarea Director
  const editTaskProfessorDirector = (event) => {
    event.preventDefault();
    if (!entregaEditTask.trim()) {
      Swal.fire({
        title: "¡Error!",
        text: "Debes agregar una fecha de entrega",
        icon: "warning",
        confirmButtonText: "Ok",
      });
      return;
    }
    const editTarea = {
      Tarea: {
        publicar: true,
        publicarAlumno: publicarAlumno,
        grado: gradoEditTask,
        materia: materiaEditTask,
        fechaEntrega: entregaEditTask,
        semana: semanaEditTask,
        contenido: contenidoEditTask,
        linkVideo1: linkEditTask1,
        linkVideo2: linkEditTask2,
        linkVideo3: linkEditTask3,
        linkVideo4: linkEditTask4,
        linkVideo5: linkEditTask5,
        linkVideo6: linkEditTask6,
        linkArchivo1: linkEditTask7,
        linkArchivo2: linkEditTask8,
        linkArchivo3: linkEditTask9,
        linkArchivo4: linkEditTask10,
        linkArchivo5: linkEditTask11,
        linkArchivo6: linkEditTask12,
        linkSesionZoom: linkEditSesionZoom,
        sesionDate: editSesionDate,
        urlFilePDF1: urlFilePDF,
        urlFilePDF2: urlFilePDF2,
        urlFilePDF3: urlFilePDF3,
        urlFilePDF4: urlFilePDF4,
        urlFilePDF5: urlFilePDF5,
        urlFilePDF6: urlFilePDF6,
      },
    };
    try {
      db.collection(`${materiaSearch}_22-23`)
        .doc(
          `Semana${semanaSearch}_${gradoSearch}_${materiaSearch.toLowerCase()}`
        )
        .update(editTarea);
      //Reset form
      setModeEditTask(false);
      setSemanaTask(0);
      setMateriaSearch("");
      setSemanaSearch("");
      setGradoSearch("");
      setGradoTask("");
      setMateriaTask("");
      setMateriaEditTask("");
      setGradoEditTask("");
      setSemanaEditTask(0);
      setEntregaEditTask("");
      setContenidoEditTask("");
      setEditLinkTask1("");
      setEditLinkTask2("");
      setEditLinkTask3("");
      setEditLinkTask4("");
      setEditLinkTask5("");
      setEditLinkTask6("");
      setEditLinkTask7("");
      setEditLinkTask8("");
      setEditLinkTask9("");
      setEditLinkTask10("");
      setEditLinkSesionZoom("");
      setEditSesionDate("");
      setEditLinkSesionZoom2("");
      setEditSesionDate2("");
      setUrlFilePDF("");
      setUrlFilePDF2("");
      setUrlFilePDF3("");
      setUrlFilePDF4("");
      setUrlFilePDF5("");
      setUrlFilePDF6("");
      Swal.fire({
        title: "¡Excelente!",
        text: "La tarea se ha sido editada correctamente",
        icon: "success",
        confirmButtonText: "Ok",
      });
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <h2 className="mb-4 text-center margin-top">Editar Tareas</h2>
          <div className="tasks-professor__title my-5">
            <h4>
              Puedes editar una tarea seleccionando el grado, materia y semana.
            </h4>
          </div>
          {modeEditTask ? null : (
            <div className="col-md-12 tasks-container">
              <form
                onSubmit={
                  userData.idRolePermisos
                    ? handleMateriaSearchDirector
                    : handleMateriaSearch
                }
              >
                <div className="form-group">
                  {/* {userData.idRolePermisos && (
                    <Fragment>
                      <label>Profesor</label>
                      <select
                        className="form-control mb-4"
                        value={profesorSearch}
                        onChange={(event) =>
                          setProfesorSearch(event.target.value)
                        }
                      >
                        {userData.profesores.map((profesor, index) => {
                          return <option key={index}>{profesor}</option>;
                        })}
                      </select>
                    </Fragment>
                  )} */}
                  <label>Grado</label>
                  <select
                    className="form-control mb-4"
                    value={gradoSearch}
                    onChange={(event) => setGradoSearch(event.target.value)}
                  >
                    {userData.grados.map((grado, index) => {
                      return <option key={index}>{grado}</option>;
                    })}
                  </select>
                  <label>Materia</label>
                  <select
                    className="form-control mb-4"
                    value={materiaSearch}
                    onChange={(event) => setMateriaSearch(event.target.value)}
                  >
                    {userData.materias.map((materia, index) => {
                      return <option key={index}>{materia}</option>;
                    })}
                  </select>
                  <label>Semana</label>
                  <input
                    type="number"
                    placeholder="Ingresa la semana"
                    className="form-control mb-4"
                    value={semanaSearch}
                    onChange={(event) => setSemanaSearch(event.target.value)}
                  />
                </div>
                <button className="btn-searchTask mt-3 " type="submit">
                  Buscar
                </button>
              </form>
            </div>
          )}
        </div>
        {/* EDIT TASK */}
        {modeEditTask && (
          <div className="col-md-12">
            <form
              onSubmit={
                userData.idRolePermisos
                  ? editTaskProfessorDirector
                  : editTaskProfessor
              }
            >
              <div className="form-group formTasks">
                <label>
                  Fecha de Entrega
                  <input
                    type="text"
                    name="fechaTask"
                    placeholder="Ingresa la fecha de entrega"
                    className="form-control mb-4"
                    value={entregaEditTask}
                    onChange={(event) => setEntregaEditTask(event.target.value)}
                  />
                </label>

                <label>
                  Contenido
                  <textarea
                    type="text"
                    name="contenidoTask"
                    placeholder="Ingresa las instrucciones de entrega"
                    rows="3"
                    className="form-control mb-4"
                    value={contenidoEditTask}
                    onChange={(event) =>
                      setContenidoEditTask(event.target.value)
                    }
                  />
                </label>
                <div className="container-links">
                  <label>
                    Link de Vídeo
                    <input
                      type="text"
                      name="linkTask1"
                      placeholder="Ingresa el link del vídeo (YouTube)"
                      className="form-control mb-4"
                      value={linkEditTask1}
                      onChange={(event) => setEditLinkTask1(event.target.value)}
                    />
                  </label>
                  <label>
                    Link de Vídeo
                    <input
                      type="text"
                      name="linkTask2"
                      placeholder="Ingresa el link del vídeo (YouTube)"
                      className="form-control mb-4"
                      value={linkEditTask2}
                      onChange={(event) => setEditLinkTask2(event.target.value)}
                    />
                  </label>
                  <label>
                    Link de Vídeo
                    <input
                      type="text"
                      name="linkTask5"
                      placeholder="Ingresa el link del vídeo (YouTube)"
                      className="form-control mb-4"
                      value={linkEditTask3}
                      onChange={(event) => setEditLinkTask3(event.target.value)}
                    />
                  </label>
                  <label>
                    Link de Vídeo
                    <input
                      type="text"
                      name="linkTask6"
                      placeholder="Ingresa el link del vídeo (YouTube)"
                      className="form-control mb-4"
                      value={linkEditTask4}
                      onChange={(event) => setEditLinkTask4(event.target.value)}
                    />
                  </label>
                  <label>
                    Link de Vídeo
                    <input
                      type="text"
                      name="linkTask7"
                      placeholder="Ingresa el link del vídeo (YouTube)"
                      className="form-control mb-4"
                      value={linkEditTask5}
                      onChange={(event) => setEditLinkTask5(event.target.value)}
                    />
                  </label>
                  <label>
                    Link de Vídeo
                    <input
                      type="text"
                      name="linkTask8"
                      placeholder="Ingresa el link del vídeo (YouTube)"
                      className="form-control mb-4"
                      value={linkEditTask6}
                      onChange={(event) => setEditLinkTask6(event.target.value)}
                    />
                  </label>
                  <label>
                    Link de recurso externo
                    <input
                      type="text"
                      name="linkTask3"
                      placeholder="Ingresa el link"
                      className="form-control mb-4"
                      value={linkEditTask7}
                      onChange={(event) => setEditLinkTask7(event.target.value)}
                    />
                  </label>
                  <label>
                    Link de recurso externo
                    <input
                      type="text"
                      name="linkTask4"
                      placeholder="Ingresa el link"
                      className="form-control mb-4"
                      value={linkEditTask8}
                      onChange={(event) => setEditLinkTask8(event.target.value)}
                    />
                  </label>
                  <label>
                    Link de recurso externo
                    <input
                      type="text"
                      name="linkTask9"
                      placeholder="Ingresa el link"
                      className="form-control mb-4"
                      value={linkEditTask9}
                      onChange={(event) => setEditLinkTask9(event.target.value)}
                    />
                  </label>
                  <label>
                    Link de recurso externo
                    <input
                      type="text"
                      name="linkTask10"
                      placeholder="Ingresa el link"
                      className="form-control mb-4"
                      value={linkEditTask10}
                      onChange={(event) =>
                        setEditLinkTask10(event.target.value)
                      }
                    />
                  </label>
                  <label>
                    Link de recurso externo
                    <input
                      type="text"
                      name="linkTask11"
                      placeholder="Ingresa el link"
                      className="form-control mb-4"
                      value={linkEditTask11}
                      onChange={(event) =>
                        setEditLinkTask11(event.target.value)
                      }
                    />
                  </label>
                  <label>
                    Link de recurso externo
                    <input
                      type="text"
                      name="linkTask12"
                      placeholder="Ingresa el link"
                      className="form-control mb-4"
                      value={linkEditTask12}
                      onChange={(event) =>
                        setEditLinkTask12(event.target.value)
                      }
                    />
                  </label>
                </div>
                <div className="container-files">
                  {/* PDF */}
                  <label className="input-file-upload">
                    Selecciona un archivo
                    <FileUploader
                      className="input-custom"
                      accept=".PDF"
                      name="archivoTask"
                      randomizeFilename
                      storageRef={storage.ref(
                        `${userData.emailProfesor}/tareas`
                      )}
                      onUploadStart={handleUploadStartPDF}
                      onUploadError={handleUploadErrorPDF}
                      onUploadSuccess={handleUploadSuccessPDF}
                      onProgress={handleProgressPDF}
                    />
                    {proFilePDF.progress > 1 ? (
                      <ProgressBar
                        completed={proFilePDF.progress}
                        bgColor="rgb(255, 24, 24)"
                      />
                    ) : null}
                    {messageUploadPDF && (
                      <p className="message-upload">
                        El archivo se ha subido correctamente
                      </p>
                    )}
                    {nameFilePDF && <p>{nameFilePDF}</p>}
                  </label>
                  {/* PDF */}
                  {/* PDF2 */}
                  <label className="input-file-upload">
                    Selecciona un archivo
                    <FileUploader
                      className="input-custom"
                      accept=".PDF"
                      name="archivoTask"
                      randomizeFilename
                      storageRef={storage.ref(
                        `${userData.emailProfesor}/tareas`
                      )}
                      onUploadStart={handleUploadStartPDF2}
                      onUploadError={handleUploadErrorPDF2}
                      onUploadSuccess={handleUploadSuccessPDF2}
                      onProgress={handleProgressPDF2}
                    />
                    {proFilePDF2.progress > 1 ? (
                      <ProgressBar
                        completed={proFilePDF2.progress}
                        bgColor="rgb(255, 24, 24)"
                      />
                    ) : null}
                    {messageUploadPDF2 && (
                      <p className="message-upload">
                        El archivo se ha subido correctamente
                      </p>
                    )}
                    {nameFilePDF2 && <p>{nameFilePDF2}</p>}
                  </label>
                  {/* PDF2 */}
                  {/* PDF3 */}
                  <label className="input-file-upload">
                    Selecciona un archivo
                    <FileUploader
                      className="input-custom"
                      accept=".PDF"
                      name="archivoTask"
                      randomizeFilename
                      storageRef={storage.ref(
                        `${userData.emailProfesor}/tareas`
                      )}
                      onUploadStart={handleUploadStartPDF3}
                      onUploadError={handleUploadErrorPDF3}
                      onUploadSuccess={handleUploadSuccessPDF3}
                      onProgress={handleProgressPDF3}
                    />
                    {proFilePDF3.progress > 1 ? (
                      <ProgressBar
                        completed={proFilePDF3.progress}
                        bgColor="rgb(255, 24, 24) "
                      />
                    ) : null}
                    {messageUploadPDF3 && (
                      <p className="message-upload">
                        El archivo se ha subido correctamente
                      </p>
                    )}
                    {nameFilePDF3 && <p>{nameFilePDF3}</p>}
                  </label>
                  {/* PDF3 */}
                  {/* PDF4 */}
                  <label className="input-file-upload">
                    Selecciona un archivo
                    <FileUploader
                      className="input-custom"
                      accept=".PDF"
                      name="archivoTask"
                      randomizeFilename
                      storageRef={storage.ref(
                        `${userData.emailProfesor}/tareas`
                      )}
                      onUploadStart={handleUploadStartPDF4}
                      onUploadError={handleUploadErrorPDF4}
                      onUploadSuccess={handleUploadSuccessPDF4}
                      onProgress={handleProgressPDF4}
                    />
                    {proFilePDF4.progress > 1 ? (
                      <ProgressBar
                        completed={proFilePDF4.progress}
                        bgColor="rgb(255, 24, 24)"
                      />
                    ) : null}
                    {messageUploadPDF4 && (
                      <p className="message-upload">
                        El archivo se ha subido correctamente
                      </p>
                    )}
                    {nameFilePDF4 && <p>{nameFilePDF4}</p>}
                  </label>
                  {/* PDF4 */}
                  {/* PDF5 */}
                  <label className="input-file-upload">
                    Selecciona un archivo
                    <FileUploader
                      className="input-custom"
                      accept=".PDF"
                      name="archivoTask"
                      randomizeFilename
                      storageRef={storage.ref(
                        `${userData.emailProfesor}/tareas`
                      )}
                      onUploadStart={handleUploadStartPDF5}
                      onUploadError={handleUploadErrorPDF5}
                      onUploadSuccess={handleUploadSuccessPDF5}
                      onProgress={handleProgressPDF5}
                    />
                    {proFilePDF5.progress > 1 ? (
                      <ProgressBar
                        completed={proFilePDF5.progress}
                        bgColor="rgb(255, 24, 24)"
                      />
                    ) : null}
                    {messageUploadPDF5 && (
                      <p className="message-upload">
                        El archivo se ha subido correctamente
                      </p>
                    )}
                    {nameFilePDF5 && <p>{nameFilePDF5}</p>}
                  </label>
                  {/* PDF5 */}
                  {/* PDF6 */}
                  <label className="input-file-upload">
                    Selecciona un archivo
                    <FileUploader
                      className="input-custom"
                      accept=".PDF"
                      name="archivoTask"
                      randomizeFilename
                      storageRef={storage.ref(
                        `${userData.emailProfesor}/tareas`
                      )}
                      onUploadStart={handleUploadStartPDF6}
                      onUploadError={handleUploadErrorPDF6}
                      onUploadSuccess={handleUploadSuccessPDF6}
                      onProgress={handleProgressPDF6}
                    />
                    {proFilePDF6.progress > 1 ? (
                      <ProgressBar
                        completed={proFilePDF4.progress}
                        bgColor="rgb(255, 24, 24)"
                      />
                    ) : null}
                    {messageUploadPDF6 && (
                      <p className="message-upload">
                        El archivo se ha subido correctamente
                      </p>
                    )}
                    {nameFilePDF6 && <p>{nameFilePDF6}</p>}
                  </label>
                  {/* PDF6 */}
                </div>
                <div className="container-links-zoom">
                  <label>
                    Link de Zoom
                    <input
                      type="text"
                      name="linkSesionZoom"
                      placeholder="Ingresa el link de la sesión de Zoom"
                      className="form-control mb-4"
                      value={linkEditSesionZoom}
                      onChange={(event) =>
                        setEditLinkSesionZoom(event.target.value)
                      }
                    />
                  </label>
                  <label>
                    Fecha y hora
                    <input
                      type="text"
                      name="link"
                      placeholder="Ingresa la fecha y hora de la sesión"
                      className="form-control mb-4"
                      value={editSesionDate}
                      onChange={(event) =>
                        setEditSesionDate(event.target.value)
                      }
                    />
                  </label>
                  <label>
                    Link de Zoom 2
                    <input
                      type="text"
                      name="linkSesionZoom"
                      placeholder="Ingresa el link de la sesión de Zoom"
                      className="form-control mb-4"
                      value={linkEditSesionZoom2}
                      onChange={(event) =>
                        setEditLinkSesionZoom2(event.target.value)
                      }
                    />
                  </label>
                  <label>
                    Fecha y hora
                    <input
                      type="text"
                      name="link"
                      placeholder="Ingresa la fecha y hora de la sesión"
                      className="form-control mb-4"
                      value={editSesionDate2}
                      onChange={(event) =>
                        setEditSesionDate2(event.target.value)
                      }
                    />
                  </label>
                  {userData.idRolePermisos && (
                    <div className="container-checked">
                      <p>Publicar:</p>
                      <Switch
                        onChange={handleChecked}
                        onColor={"#1a237e"}
                        checked={publicarAlumno}
                      />
                    </div>
                  )}
                </div>
                <div className="container-btns-addTask">
                  <div className="btn-addTask">
                    <button
                      className="btn-addTask"
                      type="submit"
                      value="Editar"
                      onSubmit={editTaskProfessor}
                    >
                      Editar
                    </button>
                  </div>
                  <div className="btn-cancel">
                    <button
                      type="button"
                      onClick={() => setModeEditTask(false)}
                    >
                      Cancelar
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        )}
      </div>
    </div>
  );
};

export default EditTask;
