import React, { useState, Fragment } from "react";
import "./AddTask.css";
import FileUploader from "react-firebase-file-uploader";
import ProgressBar from "@ramonak/react-progress-bar";
import Swal from "sweetalert2";
import { db, storage } from "../../../firebase";
import Switch from "react-switch";

const AddTask = (props) => {
  const { userData } = props;

  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: "btn btn-success",
      cancelButton: "btn btn-danger",
    },
    buttonsStyling: false,
  });

  //useState
  const [modeAddTask, setModeAddTask] = useState(false);
  const [materiaTask, setMateriaTask] = useState("");
  const [entregaTask, setEntregaTask] = useState("");
  const [gradoTask, setGradoTask] = useState("");
  const [semanaTask, setSemanaTask] = useState("0");
  const [contenidoTask, setContenidoTask] = useState("");
  const [linkTask1, setLinkTask1] = useState("");
  const [linkTask2, setLinkTask2] = useState("");
  const [linkTask3, setLinkTask3] = useState("");
  const [linkTask4, setLinkTask4] = useState("");
  const [linkTask5, setLinkTask5] = useState("");
  const [linkTask6, setLinkTask6] = useState("");
  const [linkTask7, setLinkTask7] = useState("");
  const [linkTask8, setLinkTask8] = useState("");
  const [linkTask9, setLinkTask9] = useState("");
  const [linkTask10, setLinkTask10] = useState("");
  const [linkTask11, setLinkTask11] = useState("");
  const [linkTask12, setLinkTask12] = useState("");
  const [linkSesionZoom, setLinkSesionZoom] = useState("");
  const [linkSesionZoom2, setLinkSesionZoom2] = useState("");
  const [sesionDate, setSesionDate] = useState("");
  const [sesionDate2, setSesionDate2] = useState("");
  const [publicarAlumno, setPublicarAlumno] = useState(false);

  //PDF
  const [nameFileVideo, setNameFileVideo] = useState("");
  const [uploadFileVideo, setUploadFileVideo] = useState(false);
  const [proFileVideo, setProFileVideo] = useState(0);
  const [urlFileVideo, setUrlFileVideo] = useState("");
  const [messageUploadVideo, setMessageUploadVideo] = useState(false);
  //PDF
  const [nameFileVideo2, setNameFileVideo2] = useState("");
  const [uploadFileVideo2, setUploadFileVideo2] = useState(false);
  const [proFileVideo2, setProFileVideo2] = useState(0);
  const [urlFileVideo2, setUrlFileVideo2] = useState("");
  const [messageUploadVideo2, setMessageUploadVideo2] = useState(false);
  //PDF
  const [nameFilePDF, setNameFilePDF] = useState("");
  const [uploadFilePDF, setUploadFilePDF] = useState(false);
  const [proFilePDF, setProFilePDF] = useState(0);
  const [urlFilePDF, setUrlFilePDF] = useState("");
  const [messageUploadPDF, setMessageUploadPDF] = useState(false);
  //PDF02
  const [nameFilePDF2, setNameFilePDF2] = useState("");
  const [uploadFilePDF2, setUploadFilePDF2] = useState(false);
  const [proFilePDF2, setProFilePDF2] = useState(0);
  const [urlFilePDF2, setUrlFilePDF2] = useState("");
  const [messageUploadPDF2, setMessageUploadPDF2] = useState(false);
  //PDF03
  const [nameFilePDF3, setNameFilePDF3] = useState("");
  const [uploadFilePDF3, setUploadFilePDF3] = useState(false);
  const [proFilePDF3, setProFilePDF3] = useState(0);
  const [urlFilePDF3, setUrlFilePDF3] = useState("");
  const [messageUploadPDF3, setMessageUploadPDF3] = useState(false);
  //PDF04
  const [nameFilePDF4, setNameFilePDF4] = useState("");
  const [uploadFilePDF4, setUploadFilePDF4] = useState(false);
  const [proFilePDF4, setProFilePDF4] = useState(0);
  const [urlFilePDF4, setUrlFilePDF4] = useState("");
  const [messageUploadPDF4, setMessageUploadPDF4] = useState(false);

  const handleChecked = () => {
    setPublicarAlumno(!publicarAlumno);
    console.log(!publicarAlumno);
  };

  //Vídeo
  const handleUploadStartVideo = () => {
    setProFileVideo(0);
    setUploadFileVideo(true);
  };
  const handleProgressVideo = (progress) =>
    setProFileVideo({
      progress,
    });

  const handleUploadErrorVideo = (error) => {
    setUploadFileVideo(error);
    console.log(error);
  };
  const handleUploadSuccessVideo = (name) => {
    setProFileVideo(100);
    setUploadFileVideo(false);
    setNameFileVideo(name);
    storage
      .ref(`${userData.emailProfesor}/tareas`)
      .child(name)
      .getDownloadURL()
      .then((url) => {
        setUrlFileVideo(url);
      });
    setMessageUploadVideo(true);
  };

  //Vídeo02
  const handleUploadStartVideo2 = () => {
    setProFileVideo2(0);
    setUploadFileVideo2(true);
  };
  const handleProgressVideo2 = (progress) =>
    setProFileVideo2({
      progress,
    });

  const handleUploadErrorVideo2 = (error) => {
    setUploadFileVideo2(error);
    console.log(error);
  };
  const handleUploadSuccessVideo2 = (name) => {
    setProFileVideo2(100);
    setUploadFileVideo2(false);
    setNameFileVideo2(name);
    storage
      .ref(`${userData.emailProfesor}/tareas`)
      .child(name)
      .getDownloadURL()
      .then((url) => {
        setUrlFileVideo2(url);
      });
    setMessageUploadVideo2(true);
  };
  //PDF
  const handleUploadStartPDF = () => {
    setProFilePDF(0);
    setUploadFilePDF(true);
  };
  const handleProgressPDF = (progress) =>
    setProFilePDF({
      progress,
    });

  const handleUploadErrorPDF = (error) => {
    setUploadFilePDF(error);
    console.log(error);
  };
  const handleUploadSuccessPDF = (name) => {
    setProFilePDF(100);
    setUploadFilePDF(false);
    setNameFilePDF(name);
    storage
      .ref(`${userData.emailProfesor}/tareas`)
      .child(name)
      .getDownloadURL()
      .then((url) => {
        setUrlFilePDF(url);
      });
    setMessageUploadPDF(true);
  };
  //PDF02
  const handleUploadStartPDF2 = () => {
    setProFilePDF2(0);
    setUploadFilePDF2(true);
  };
  const handleProgressPDF2 = (progress) =>
    setProFilePDF2({
      progress,
    });

  const handleUploadErrorPDF2 = (error) => {
    setUploadFilePDF2(error);
    console.log(error);
  };
  const handleUploadSuccessPDF2 = (name) => {
    setProFilePDF2(100);
    setUploadFilePDF2(false);
    setNameFilePDF2(name);
    storage
      .ref(`${userData.emailProfesor}/tareas`)
      .child(name)
      .getDownloadURL()
      .then((url) => {
        setUrlFilePDF2(url);
      });
    setMessageUploadPDF2(true);
  };
  //PDF03
  const handleUploadStartPDF3 = () => {
    setProFilePDF3(0);
    setUploadFilePDF3(true);
  };
  const handleProgressPDF3 = (progress) =>
    setProFilePDF3({
      progress,
    });

  const handleUploadErrorPDF3 = (error) => {
    setUploadFilePDF3(error);
    console.log(error);
  };
  const handleUploadSuccessPDF3 = (name) => {
    setProFilePDF3(100);
    setUploadFilePDF3(false);
    setNameFilePDF3(name);
    storage
      .ref(`${userData.emailProfesor}/tareas`)
      .child(name)
      .getDownloadURL()
      .then((url) => {
        setUrlFilePDF3(url);
      });
    setMessageUploadPDF3(true);
  };
  //PDF04
  const handleUploadStartPDF4 = () => {
    setProFilePDF4(0);
    setUploadFilePDF4(true);
  };
  const handleProgressPDF4 = (progress) =>
    setProFilePDF4({
      progress,
    });

  const handleUploadErrorPDF4 = (error) => {
    setUploadFilePDF4(error);
    console.log(error);
  };
  const handleUploadSuccessPDF4 = (name) => {
    setProFilePDF4(100);
    setUploadFilePDF4(false);
    setNameFilePDF4(name);
    storage
      .ref(`${userData.emailProfesor}/tareas`)
      .child(name)
      .getDownloadURL()
      .then((url) => {
        setUrlFilePDF4(url);
      });
    setMessageUploadPDF4(true);
  };

  //Función para agregar una tarea DIRECTOR
  const addTaskProfessorDirector = (event) => {
    event.preventDefault();
    if (!materiaTask.trim()) {
      Swal.fire({
        title: "¡Error!",
        text: "Debes agregar el nombre de la materia",
        icon: "warning",
        confirmButtonText: "Ok",
      });
      return;
    } else if (!gradoTask.trim()) {
      Swal.fire({
        title: "¡Error!",
        text: "Debes garegar el grado",
        icon: "warning",
        confirmButtonText: "Ok",
      });
      return;
    } else if (!semanaTask) {
      Swal.fire({
        title: "¡Error!",
        text: "Debes agregar una semana",
        icon: "warning",
        confirmButtonText: "Ok",
      });

      return;
    } else if (semanaTask < 0) {
      Swal.fire({
        title: "¡Error!",
        text: "El valor de la semana no puede ser menor o igual a 0",
        icon: "warning",
        confirmButtonText: "Ok",
      });

      return;
    } else if (!entregaTask.trim()) {
      Swal.fire({
        title: "¡Error!",
        text: "Debes agregar una fecha de entrega",
        icon: "warning",
        confirmButtonText: "Ok",
      });
      return;
    } else if (materiaTask === "selecciona una opción por favor") {
      Swal.fire({
        title: "¡Error!",
        text: "La materia no es válida",
        icon: "warning",
        confirmButtonText: "Ok",
      });
      return;
    } else if (gradoTask === "selecciona una opción por favor") {
      Swal.fire({
        title: "¡Error!",
        text: "El grado no es válido",
        icon: "warning",
        confirmButtonText: "Ok",
      });
      return;
    }
    //confirmación del envío de tarea
    swalWithBootstrapButtons
      .fire({
        title: "¿Estás seguro que deseas agregar la tarea? 👀",
        text: `Grado: ${gradoTask} Materia: ${materiaTask} Semana: ${semanaTask}`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Sí, agregar",
        cancelButtonText: "No, cancelar",
        reverseButtons: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          // se crea el objeto con la tarea editada
          const nuevaTarea = {
            Tarea: {
              date: Date.now(),
              publicar: true,
              publicarAlumno,
              grado: gradoTask,
              materia: materiaTask,
              fechaEntrega: entregaTask,
              semana: semanaTask,
              contenido: contenidoTask,
              linkVideo1: linkTask1,
              linkVideo2: linkTask2,
              linkVideo3: linkTask5,
              linkVideo4: linkTask6,
              linkVideo5: linkTask7,
              linkVideo6: linkTask8,
              linkArchivo1: linkTask3,
              linkArchivo2: linkTask4,
              linkArchivo3: linkTask9,
              linkArchivo4: linkTask10,
              linkArchivo5: linkTask11,
              linkArchivo6: linkTask12,
              linkSesionZoom: linkSesionZoom,
              linkSesionZoom2: linkSesionZoom2,
              sesionDate: sesionDate,
              sesionDate2: sesionDate2,
              urlFilePDF1: urlFileVideo,
              urlFilePDF2: urlFileVideo2,
              urlFilePDF3: urlFilePDF,
              urlFilePDF4: urlFilePDF2,
              urlFilePDF5: urlFilePDF3,
              urlFilePDF6: urlFilePDF4,
            },
          };
          //se ejecuta la edición de tarea
          try {
            db.collection(`${materiaTask}_22-23`)
              .doc(
                `Semana${semanaTask}_${gradoTask}_${materiaTask.toLowerCase()}`
              )
              .set(nuevaTarea);
            //Reset form
            setGradoTask("");
            setMateriaTask("");
            setEntregaTask("");
            setSemanaTask("");
            setContenidoTask("");
            setModeAddTask(false);
            setMessageUploadVideo(false);
            setLinkSesionZoom("");
            setLinkSesionZoom2("");
            setPublicarAlumno(false);
          } catch (error) {
            console.log(error);
          }
          swalWithBootstrapButtons.fire(
            "Agregada",
            "La tarea se ha agregado correctamente. 🤠",
            "success"
          );
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) {
          swalWithBootstrapButtons.fire(
            "Cancelado",
            "El envío de la tarea se ha cancelado. 😁 ",
            "error"
          );
        }
      });
  };

  //función para agregar una tarea
  const addTaskProfessor = (event) => {
    event.preventDefault();
    if (!materiaTask.trim()) {
      Swal.fire({
        title: "¡Error!",
        text: "Debes agregar el nombre de la materia.",
        icon: "warning",
        confirmButtonText: "Ok",
      });
      return;
    } else if (!gradoTask.trim()) {
      Swal.fire({
        title: "¡Error!",
        text: "Debes garegar el grado.",
        icon: "warning",
        confirmButtonText: "Ok",
      });
      return;
    } else if (!semanaTask) {
      Swal.fire({
        title: "¡Error!",
        text: "Debes agregar una semana.",
        icon: "warning",
        confirmButtonText: "Ok",
      });

      return;
    } else if (semanaTask < 0) {
      Swal.fire({
        title: "¡Error!",
        text: "El valor de la semana no puede ser menor o igual a 0.",
        icon: "warning",
        confirmButtonText: "Ok",
      });
      return;
    } else if (!entregaTask.trim()) {
      Swal.fire({
        title: "¡Error!",
        text: "Debes agregar una fecha de entrega.",
        icon: "warning",
        confirmButtonText: "Ok",
      });
      return;
    } else if (materiaTask === "selecciona una opción por favor.") {
      Swal.fire({
        title: "¡Error!",
        text: "La materia no es válida",
        icon: "warning",
        confirmButtonText: "Ok",
      });
      return;
    } else if (gradoTask === "selecciona una opción por favor.") {
      Swal.fire({
        title: "¡Error!",
        text: "El grado no es válido",
        icon: "warning",
        confirmButtonText: "Ok",
      });
      return;
    }
    //confirmación del envío de tarea
    swalWithBootstrapButtons
      .fire({
        title: "¿Estás seguro que deseas agregar la tarea? 👀",
        text: `Grado: ${gradoTask} Materia: ${materiaTask} Semana: ${semanaTask}`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Sí, agregar",
        cancelButtonText: "No, cancelar",
        reverseButtons: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          // se crea el objeto con la tarea editada
          const nuevaTarea = {
            Tarea: {
              date: Date.now(),
              publicar: true,
              publicarAlumno: false,
              grado: gradoTask,
              materia: materiaTask,
              fechaEntrega: entregaTask,
              semana: semanaTask,
              contenido: contenidoTask,
              linkVideo1: linkTask1,
              linkVideo2: linkTask2,
              linkVideo3: linkTask5,
              linkVideo4: linkTask6,
              linkVideo5: linkTask7,
              linkVideo6: linkTask8,
              linkArchivo1: linkTask3,
              linkArchivo2: linkTask4,
              linkArchivo3: linkTask9,
              linkArchivo4: linkTask10,
              linkArchivo5: linkTask11,
              linkArchivo6: linkTask12,
              linkSesionZoom: linkSesionZoom,
              linkSesionZoom2: linkSesionZoom2,
              sesionDate: sesionDate,
              sesionDate2: sesionDate2,
              urlFilePDF1: urlFileVideo,
              urlFilePDF2: urlFileVideo2,
              urlFilePDF3: urlFilePDF,
              urlFilePDF4: urlFilePDF2,
              urlFilePDF5: urlFilePDF3,
              urlFilePDF6: urlFilePDF4,
            },
          };
          //se ejecuta la edición de tarea
          try {
            db.collection(`${materiaTask}_22-23`)
              .doc(
                `Semana${semanaTask}_${gradoTask}_${materiaTask.toLowerCase()}`
              )
              .set(nuevaTarea);
            //Reset form
            setGradoTask("");
            setMateriaTask("");
            setEntregaTask("");
            setSemanaTask("");
            setContenidoTask("");
            setModeAddTask(false);
            setMessageUploadVideo(false);
            setPublicarAlumno(false);
          } catch (error) {
            console.log(error);
          }
          swalWithBootstrapButtons.fire(
            "Agregada",
            "La tarea se ha agregado correctamente.🤠",
            "success"
          );
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) {
          swalWithBootstrapButtons.fire(
            "Cancelado",
            "El envío de la tarea se ha cancelado. 😁 ",
            "error"
          );
        }
      });
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <h2 className="margin-top text-center">Nueva Tarea</h2>
          <p className="text-center text-message">
            Por favor asegúrate de que todos los campos sean correctos.
          </p>
          {modeAddTask ? (
            <div className="col-md-12">
              <form
                onSubmit={
                  userData.idRolePermisos
                    ? addTaskProfessorDirector
                    : addTaskProfessor
                }
              >
                <div className="form-group formTasks">
                  <label>
                    Grado
                    <select
                      name="gradoTask"
                      className="form-control mb-4"
                      placeholder="Grado"
                      value={gradoTask}
                      onChange={(event) => setGradoTask(event.target.value)}
                    >
                      {userData.grados.map((grado, index) => {
                        return (
                          <option key={index} value={grado}>
                            {grado}
                          </option>
                        );
                      })}
                    </select>
                  </label>
                  <label>
                    Materia
                    <select
                      name="materiaTask"
                      className="form-control mb-4"
                      placeholder="Nombre de la materia"
                      value={materiaTask}
                      onChange={(event) => setMateriaTask(event.target.value)}
                    >
                      {userData.materias.map((materia, index) => {
                        return (
                          <option key={index} value={materia}>
                            {materia}
                          </option>
                        );
                      })}
                    </select>
                  </label>
                  <label>
                    Fecha de Entrega
                    <input
                      type="text"
                      name="fechaTask"
                      placeholder="Ingresa la fecha de entrega"
                      className="form-control mb-4"
                      value={entregaTask}
                      onChange={(event) => setEntregaTask(event.target.value)}
                    />
                  </label>
                  <label>
                    Semana
                    <input
                      type="number"
                      name="semanaTask"
                      placeholder="Ingresa la semana"
                      className="form-control mb-4"
                      value={semanaTask}
                      onChange={(event) => setSemanaTask(event.target.value)}
                    />
                  </label>
                  <label>
                    Contenido
                    <textarea
                      type="text"
                      name="contenidoTask"
                      placeholder="Ingresa las instrucciones de entrega"
                      rows="3"
                      className="form-control mb-4"
                      value={contenidoTask}
                      onChange={(event) => setContenidoTask(event.target.value)}
                    />
                  </label>
                  <div className="container-links">
                    <label>
                      Link de Vídeo
                      <input
                        type="text"
                        name="linkTask1"
                        placeholder="Ingresa el link del vídeo (YouTube)"
                        className="form-control mb-4"
                        value={linkTask1}
                        onChange={(event) => setLinkTask1(event.target.value)}
                      />
                    </label>
                    <label>
                      Link de Vídeo
                      <input
                        type="text"
                        name="linkTask2"
                        placeholder="Ingresa el link del vídeo (YouTube)"
                        className="form-control mb-4"
                        value={linkTask2}
                        onChange={(event) => setLinkTask2(event.target.value)}
                      />
                    </label>
                    <label>
                      Link de Vídeo
                      <input
                        type="text"
                        name="linkTask5"
                        placeholder="Ingresa el link del vídeo (YouTube)"
                        className="form-control mb-4"
                        value={linkTask5}
                        onChange={(event) => setLinkTask5(event.target.value)}
                      />
                    </label>
                    <label>
                      Link de Vídeo
                      <input
                        type="text"
                        name="linkTask6"
                        placeholder="Ingresa el link del vídeo (YouTube)"
                        className="form-control mb-4"
                        value={linkTask6}
                        onChange={(event) => setLinkTask6(event.target.value)}
                      />
                    </label>
                    <label>
                      Link de Vídeo
                      <input
                        type="text"
                        name="linkTask7"
                        placeholder="Ingresa el link del vídeo (YouTube)"
                        className="form-control mb-4"
                        value={linkTask7}
                        onChange={(event) => setLinkTask7(event.target.value)}
                      />
                    </label>
                    <label>
                      Link de Vídeo
                      <input
                        type="text"
                        name="linkTask8"
                        placeholder="Ingresa el link del vídeo (YouTube)"
                        className="form-control mb-4"
                        value={linkTask8}
                        onChange={(event) => setLinkTask8(event.target.value)}
                      />
                    </label>
                    <label>
                      Link de recurso externo
                      <input
                        type="text"
                        name="linkTask3"
                        placeholder="Ingresa el link"
                        className="form-control mb-4"
                        value={linkTask3}
                        onChange={(event) => setLinkTask3(event.target.value)}
                      />
                    </label>
                    <label>
                      Link de recurso externo
                      <input
                        type="text"
                        name="linkTask4"
                        placeholder="Ingresa el link"
                        className="form-control mb-4"
                        value={linkTask4}
                        onChange={(event) => setLinkTask4(event.target.value)}
                      />
                    </label>
                    <label>
                      Link de recurso externo
                      <input
                        type="text"
                        name="linkTask9"
                        placeholder="Ingresa el link"
                        className="form-control mb-4"
                        value={linkTask9}
                        onChange={(event) => setLinkTask9(event.target.value)}
                      />
                    </label>
                    <label>
                      Link de recurso externo
                      <input
                        type="text"
                        name="linkTask10"
                        placeholder="Ingresa el link"
                        className="form-control mb-4"
                        value={linkTask10}
                        onChange={(event) => setLinkTask10(event.target.value)}
                      />
                    </label>
                    <label>
                      Link de recurso externo
                      <input
                        type="text"
                        name="linkTask11"
                        placeholder="Ingresa el link"
                        className="form-control mb-4"
                        value={linkTask11}
                        onChange={(event) => setLinkTask11(event.target.value)}
                      />
                    </label>
                    <label>
                      Link de recurso externo
                      <input
                        type="text"
                        name="linkTask12"
                        placeholder="Ingresa el link"
                        className="form-control mb-4"
                        value={linkTask12}
                        onChange={(event) => setLinkTask12(event.target.value)}
                      />
                    </label>
                  </div>
                  <div className="container-files">
                    {/* Video */}
                    <label className="input-file-upload">
                      Selecciona un archivo
                      <FileUploader
                        className="input-custom"
                        accept=".PDF"
                        name="archivoTask"
                        randomizeFilename
                        storageRef={storage.ref(
                          `${userData.emailProfesor}/tareas`
                        )}
                        onUploadStart={handleUploadStartVideo}
                        onUploadError={handleUploadErrorVideo}
                        onUploadSuccess={handleUploadSuccessVideo}
                        onProgress={handleProgressVideo}
                      />
                      {proFileVideo.progress > 1 ? (
                        <ProgressBar
                          completed={proFileVideo.progress}
                          bgColor="rgb(255, 24, 24) "
                        />
                      ) : null}
                      {messageUploadVideo && (
                        <p className="message-upload">
                          El archivo se ha subido correctamente
                        </p>
                      )}
                    </label>
                    {/* Video */}
                    {/* Video2 */}
                    <label className="input-file-upload">
                      Selecciona un archivo
                      <FileUploader
                        className="input-custom"
                        accept=".PDF"
                        name="archivoTask"
                        randomizeFilename
                        storageRef={storage.ref(
                          `${userData.emailProfesor}/tareas`
                        )}
                        onUploadStart={handleUploadStartVideo2}
                        onUploadError={handleUploadErrorVideo2}
                        onUploadSuccess={handleUploadSuccessVideo2}
                        onProgress={handleProgressVideo2}
                      />
                      {proFileVideo2.progress > 1 ? (
                        <ProgressBar
                          completed={proFileVideo2.progress}
                          bgColor="rgb(255, 24, 24) "
                        />
                      ) : null}
                      {messageUploadVideo2 && (
                        <p className="message-upload">
                          El archivo se ha subido correctamente
                        </p>
                      )}
                    </label>
                    {/* Video2 */}
                    {/* PDF */}
                    <label className="input-file-upload">
                      Selecciona un archivo
                      <FileUploader
                        className="input-custom"
                        accept=".PDF"
                        name="archivoTask"
                        randomizeFilename
                        storageRef={storage.ref(
                          `${userData.emailProfesor}/tareas`
                        )}
                        onUploadStart={handleUploadStartPDF}
                        onUploadError={handleUploadErrorPDF}
                        onUploadSuccess={handleUploadSuccessPDF}
                        onProgress={handleProgressPDF}
                      />
                      {proFilePDF.progress > 1 ? (
                        <ProgressBar
                          completed={proFilePDF.progress}
                          bgColor="rgb(255, 24, 24)"
                        />
                      ) : null}
                      {messageUploadPDF && (
                        <p className="message-upload">
                          El archivo se ha subido correctamente
                        </p>
                      )}
                    </label>
                    {/* PDF */}
                    {/* PDF2 */}
                    <label className="input-file-upload">
                      Selecciona un archivo
                      <FileUploader
                        className="input-custom"
                        accept=".PDF"
                        name="archivoTask"
                        randomizeFilename
                        storageRef={storage.ref(
                          `${userData.emailProfesor}/tareas`
                        )}
                        onUploadStart={handleUploadStartPDF2}
                        onUploadError={handleUploadErrorPDF2}
                        onUploadSuccess={handleUploadSuccessPDF2}
                        onProgress={handleProgressPDF2}
                      />
                      {proFilePDF2.progress > 1 ? (
                        <ProgressBar
                          completed={proFilePDF2.progress}
                          bgColor="rgb(255, 24, 24)"
                        />
                      ) : null}
                      {messageUploadPDF2 && (
                        <p className="message-upload">
                          El archivo se ha subido correctamente
                        </p>
                      )}
                    </label>
                    {/* PDF2 */}
                    {/* PDF3 */}
                    <label className="input-file-upload">
                      Selecciona un archivo
                      <FileUploader
                        className="input-custom"
                        accept=".PDF"
                        name="archivoTask"
                        randomizeFilename
                        storageRef={storage.ref(
                          `${userData.emailProfesor}/tareas`
                        )}
                        onUploadStart={handleUploadStartPDF3}
                        onUploadError={handleUploadErrorPDF3}
                        onUploadSuccess={handleUploadSuccessPDF3}
                        onProgress={handleProgressPDF3}
                      />
                      {proFilePDF3.progress > 1 ? (
                        <ProgressBar
                          completed={proFilePDF3.progress}
                          bgColor="rgb(255, 24, 24) "
                        />
                      ) : null}
                      {messageUploadPDF3 && (
                        <p className="message-upload">
                          El archivo se ha subido correctamente
                        </p>
                      )}
                    </label>
                    {/* PDF3 */}
                    {/* PDF4 */}
                    <label className="input-file-upload">
                      Selecciona un archivo
                      <FileUploader
                        className="input-custom"
                        accept=".PDF"
                        name="archivoTask"
                        randomizeFilename
                        storageRef={storage.ref(
                          `${userData.emailProfesor}/tareas`
                        )}
                        onUploadStart={handleUploadStartPDF4}
                        onUploadError={handleUploadErrorPDF4}
                        onUploadSuccess={handleUploadSuccessPDF4}
                        onProgress={handleProgressPDF4}
                      />
                      {proFilePDF4.progress > 1 ? (
                        <ProgressBar
                          completed={proFilePDF4.progress}
                          bgColor="rgb(255, 24, 24)"
                        />
                      ) : null}
                      {messageUploadPDF4 && (
                        <p className="message-upload">
                          El archivo se ha subido correctamente
                        </p>
                      )}
                    </label>
                    {/* PDF4 */}
                  </div>
                  <div className="container-links-zoom">
                    <label>
                      Link de Zoom
                      <input
                        type="text"
                        name="linkSesionZoom"
                        placeholder="Ingresa el link de la sesión de Zoom"
                        className="form-control mb-4"
                        value={linkSesionZoom}
                        onChange={(event) =>
                          setLinkSesionZoom(event.target.value)
                        }
                      />
                    </label>
                    <label>
                      Fecha y hora
                      <input
                        type="text"
                        name="link"
                        placeholder="Ingresa la fecha y hora de la sesión"
                        className="form-control mb-4"
                        value={sesionDate}
                        onChange={(event) => setSesionDate(event.target.value)}
                      />
                    </label>
                  </div>
                  <div className="container-links-zoom">
                    <label>
                      Link de Zoom
                      <input
                        type="text"
                        name="linkSesionZoom"
                        placeholder="Ingresa el link de la sesión de Zoom"
                        className="form-control mb-4"
                        value={linkSesionZoom2}
                        onChange={(event) =>
                          setLinkSesionZoom2(event.target.value)
                        }
                      />
                    </label>
                    <label>
                      Fecha y hora
                      <input
                        type="text"
                        name="link"
                        placeholder="Ingresa la fecha y hora de la sesión"
                        className="form-control mb-4"
                        value={sesionDate2}
                        onChange={(event) => setSesionDate2(event.target.value)}
                      />
                    </label>
                  </div>
                  {userData.idRolePermisos && (
                    <div className="container-checked">
                      <p>Publicar:</p>
                      <Switch
                        onChange={handleChecked}
                        onColor={"#1a237e"}
                        checked={publicarAlumno}
                      />
                    </div>
                  )}
                  <div className="container-btns-addTask">
                    <div className="btn-addTask">
                      <button
                        className="btn-addTask"
                        type="submit"
                        value="Agregar "
                        onSubmit={addTaskProfessor}
                      >
                        Agregar
                      </button>
                    </div>
                    <div className="btn-cancel">
                      <button
                        type="button"
                        onClick={() => setModeAddTask(false)}
                      >
                        Cancelar
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          ) : (
            <div className="btn-addNewTask mt-3 ">
              <button
                data-toggle="modal"
                data-target="#exampleModal"
                type="button"
                onClick={() => setModeAddTask(true)}
              >
                Agregar una Tarea
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AddTask;
